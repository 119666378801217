import { DateTime } from 'luxon'

export const renderCoverStart = policy => {
  return dateToLocaleString(policy.coverStart)
}

export const renderCoverEnd = policy => {
  return dateToLocaleString(policy.coverage.coverEndForDisplay)
}

export function coverStartIsInFuture(policy) {
  let coverStart = DateTime.fromISO(policy.coverStart)
  return coverStart.diffNow().milliseconds >= 0
}

export function coverEndIsInPast(policy) {
  let coverEnd = DateTime.fromISO(policy.coverEnd)
  return coverEnd.diffNow().milliseconds <= 0
}

export function renderCancellationDate(policyEvents) {
  const canceledEvents = policyEvents
    .filter(event => event.type === 'PolicyCanceledEventV1')
    .sort(
      (a, b) => DateTime.fromISO(a.cancelDate) - DateTime.fromISO(b.cancelDate)
    )

  if (canceledEvents.length > 0) {
    return dateToLocaleString(canceledEvents[0].cancelDate)
  }

  return null
}

export function isCancelledInTheFuture(policyEvents) {
  let isCancelledInTheFuture = false

  for (let i = policyEvents.length - 1; i >= 0; i--) {
    if (policyEvents[i].type === 'PolicyCanceledEventV1') {
      isCancelledInTheFuture = true
    }
    if (
      policyEvents[i].eventType === 'MTA_REINSTATE' &&
      isCancelledInTheFuture
    ) {
      isCancelledInTheFuture = false
    }
    if (policyEvents[i].eventType === 'MTA_REACTIVATE') {
      isCancelledInTheFuture = false
      return isCancelledInTheFuture
    }
  }

  return isCancelledInTheFuture
}

function dateToLocaleString(date) {
  return `${DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)} Uhr`
}
